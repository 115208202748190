import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { inject, observer } from "mobx-react"
import { Divider } from "@material-ui/core"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import CartItem from "../components/cart-item"
import DeliveryForm from "../components/delivery-form"
import NameAddress from "../components/name-address"
import ShippingForm from "../components/shipping-form"
import { makeStyles } from "@material-ui/core/styles"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import { Button } from "@material-ui/core"
import DoneIcon from "@material-ui/icons/Done"
import Box from "@material-ui/core/Box"
import { SecureTransaction } from "../components/sercure-transaction"
import Checkbox from "@material-ui/core/Checkbox"
import TextField from "@material-ui/core/TextField"
import { PaymentForm } from "../components/payment-form"

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
})

const firebaseConfig = {
  apiKey: "AIzaSyC4yYUJumZOgMSgVZY5esFF8-gi807pI6g",
  authDomain: "lock-pole-defender.firebaseapp.com",
  projectId: "lock-pole-defender",
}

let functions

const grayText = "#979a9d"

const Text = styled.div`
  font: 400 16px/20px "Poppins", sans-serif;
  color: #979a9d;
`

const ContentWrapper = styled(Text)`
  width: 100%;
  padding: 0px 4px;
  margin-bottom: 10px;
`

const PageLayout = styled.div`
  display: grid;
  font-family: Helvetica;
  width: 100%;
`

const CartContent = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 5fr 3fr;
  max-width: 1200px;
  margin-top: 120px;
  h2 {
    font: 500 20px/1 "Poppins", sans-serif;
    color: #374048;
    margin: 0;
  }

  @media only screen and (max-width: 860px) {
    grid-template-columns: 1fr;
  }
`

const CartSubheader = styled.div`
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  padding-left: 4px;
  color: ${grayText};
`

const OrderSummary = styled.div`
  color: ${grayText};
  border: 1px solid rgba(0, 0, 0, 0.08);
`

const OrderContent = styled.div`
  padding: 16px;
`

const OrderSummaryHeader = styled.div`
  font: 700 15px/1.5 "Poppins", sans-serif;
  text-transform: uppercase;
  color: #374048;
  height: 60px;
  padding-left: 16px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #111;
  background-color: rgb(247, 247, 247);
`

const OrderLineItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 14px 0px;
  color: black;
`

const FormSubHeader = styled(CartSubheader)`
  margin: 16px 0;
  color: black;
`

const PaymentSection = styled.div`
  width: 100%;
`

const CreditCardForm = styled.div`
  .sq-payment-form {
    width: 100%;
  }
`

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginBottom: "16px",
  },
  heading: {
    borderRadius: 0,
    boxShadow: 0,
    background: "#201f1f",
    fontSize: "15px",
    fontWeight: 700,
    fontFamily: "Poppins,sans-serif",
    color: "white",
  },

  input: {
    color: "black",
    width: "100%",
    borderRadius: 0,
  },

  button: {
    backgroundColor: "#4A90E2",
    color: "white",
    height: "56px",
  },

  icon: {
    color: "green",
  },
}))

const ShippingAddressSummary = ({ formData, email, phone, shippingOption }) => {
  return (
    <>
      <ContentWrapper>
        <div>
          {formData.firstName.value} {formData.lastName.value}
        </div>
        {formData.companyName.value && <div>{formData.companyName.value}</div>}
        <div>
          {formData.address.value}, {formData.suite.value}
        </div>
        <div>
          {formData.city.value} {formData.state.value}, {formData.zip.value}
        </div>
      </ContentWrapper>
      {(email || phone) && <FormSubHeader>Contact Information</FormSubHeader>}
      <ContentWrapper>
        {email && <div>Email: {email}</div>}
        {phone && <div>Phone Number: {phone}</div>}
      </ContentWrapper>
    </>
  )
}

const FormHeader = ({ header, showIcon }) => {
  const classes = useStyles()
  return (
    <Box css={{ width: "100%" }} display="flex" justifyContent="space-between">
      {header}
      {showIcon ? <DoneIcon className={classes.icon} /> : <div />}
    </Box>
  )
}

const EditLink = styled.a`
  font-size: 13px;
  display: block;
  margin-top: 14px;
  margin-right: 4px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  color: #0084ff;
`

const RemovePromoText = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 13px;
  cursor: pointer;
  width: 100%;
`

let firebaseCreateOrder
let firebaseSubmitPayment
let firebaseGetOrder
let getShippingRates
const CheckOutPage = ({ cart: cartStore }) => {
  const classes = useStyles()
  const [errorMessages, setErrorMessages] = useState([])
  const [promoCodeText, setPromoCodeText] = useState(null)
  const [promoSubmitted, setPromoSubmitted] = useState(false)
  const { cart, formData } = cartStore
  const [loading, setLoading] = useState(false)
  const [orderError, setOrderError] = useState(null)
  const [completedSections, setCompletedSections] = useState({
    address: false,
    shipping: false,
    payment: false,
    review: false,
  })

  useEffect(() => {
    // check cache
    cartStore.getShippingFromSessionStorage()
    import("firebase").then(module => {
      const { default: firebase } = module
      if (firebase) {
        // if app was already initialized, use that one
        if (!firebase.apps.length) {
          firebase.initializeApp(firebaseConfig)
        }

        if (process.env.NODE_ENV === "development") {
          firebase.functions().useEmulator("localhost", 5001)
        }

        firebaseCreateOrder = firebase.functions().httpsCallable("createOrder")
        firebaseGetOrder = firebase.functions().httpsCallable("getOrder")
        firebaseSubmitPayment = firebase
          .functions()
          .httpsCallable("processPayment")
        getShippingRates = firebase.functions().httpsCallable("shippingRates")
      }
    })
  }, [])

  useEffect(() => {
    if (cartStore.cartCount === 0) {
      navigate("/")
    }
  }, [])

  const handleBillingAddress = (fieldName, value) => {
    cartStore.setBillingInfo(fieldName, value)
  }

  const cardNonceResponseReceived = async tokenResult => {
    const { errors = [], token, details } = tokenResult

    if (errors.length > 0) {
      setErrorMessages(errors.map(error => error.message))
      return
    }

    setErrorMessages([])

    // verified credit card is valid
    if (details && token) {
      cartStore.setCardData(details?.card)
      cartStore.setPaymentNonce(token)

      setCompletedSections({
        ...completedSections,
        payment: true,
      })
    }
  }

  const handleAddressSubmission = () => {
    if (cartStore.isSectionOneValid) {
      setCompletedSections({
        ...completedSections,
        address: true,
      })
    } else {
      cartStore.setDirtyForm()
      setCompletedSections({
        ...completedSections,
        address: false,
      })
    }
  }

  const handleShippingSubmission = () => {
    if (cartStore.isSectionOneValid) {
      setCompletedSections({
        ...completedSections,
        shipping: true,
      })
    } else {
      cartStore.setDirtyForm()
      setCompletedSections({
        ...completedSections,
        shipping: false,
      })
    }
  }

  const handleOrderSubmission = async () => {
    setCompletedSections({
      ...completedSections,
      payment: true,
    })

    if (cartStore.paymentNonce) {
      setLoading(true)
      try {
        const result = await firebaseCreateOrder(cartStore.order)
        if (result?.data?.data?.order) {
          cartStore.setOrderId(result.data.data.order.id)
          await firebaseSubmitPayment({
            nonce: cartStore.paymentNonce,
            form: cartStore.paymentInfo,
            order: cartStore.order,
          })
          const orderResponse = await firebaseGetOrder(cartStore.orderId)
          const {
            data: {
              data: { order = {} },
            },
          } = orderResponse

          cartStore.setOrderConfirmationData(order)
          setLoading(false)
          setOrderError(null)
          cartStore.clearCart()
          navigate(`/order-confirmation?order=${cartStore.orderId}`)
        }
      } catch (e) {
        setLoading(false)
        setOrderError(
          "Looks like something went wrong. If the problem continues please email info@lightpoledefender.com"
        )
      }
    }
  }

  const handleSameShippingAddressToggle = bool => {
    cartStore.setSameAsShipping(bool)
  }
  const shippingSelection = cartStore.shippingOption?.shippingSelection?.value
  return (
    <Layout handleCartClick={() => {}} hideSubNav hideNav>
      <SEO title="Home" />
      <PageLayout>
        <CartContent>
          <div>
            <Accordion className={classes.root} expanded={true}>
              <AccordionSummary
                className={classes.heading}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <FormHeader
                  header="1. SHIPPING ADDRESS"
                  showIcon={completedSections.address}
                />
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ width: "100%" }}>
                  <Box display="flex" justifyContent="space-between">
                    <FormSubHeader>Shipping Address</FormSubHeader>
                    {completedSections.address && (
                      <EditLink
                        onClick={() =>
                          setCompletedSections({
                            ...completedSections,
                            address: false,
                          })
                        }
                      >
                        EDIT
                      </EditLink>
                    )}
                  </Box>
                  {!completedSections.address ? (
                    <DeliveryForm
                      handleShippingSubmission={handleAddressSubmission}
                      formData={cartStore.formData}
                    />
                  ) : (
                    <ShippingAddressSummary
                      formData={cartStore.formData}
                      email={cartStore.contactInfo.email.value}
                      phone={cartStore.contactInfo.phone.value}
                      shippingOption={
                        cartStore.formData.shippingSelection.value
                      }
                    />
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              className={classes.root}
              expanded={
                (completedSections.address && !completedSections.shipping) ||
                shippingSelection
              }
            >
              <AccordionSummary
                className={classes.heading}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <FormHeader
                  header="2. SHIPPING METHOD"
                  showIcon={completedSections.shipping}
                />
              </AccordionSummary>
              <AccordionDetails>
                {!completedSections.shipping ? (
                  <ShippingForm
                    onSubmit={handleShippingSubmission}
                    getShippingRates={getShippingRates}
                  />
                ) : (
                  <div style={{ width: "100%" }}>
                    <Box w="100%" display="flex" justifyContent="space-between">
                      <FormSubHeader>Shipping Option</FormSubHeader>
                      <EditLink
                        onClick={() =>
                          setCompletedSections({
                            ...completedSections,
                            shipping: false,
                          })
                        }
                      >
                        EDIT
                      </EditLink>
                    </Box>
                    <ContentWrapper>
                      <Box display="flex">
                        <Box mr={1}>{shippingSelection.name} </Box>
                        <div>(${shippingSelection.amount})</div>
                      </Box>
                    </ContentWrapper>
                  </div>
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion
              className={classes.root}
              expanded={completedSections.shipping || cartStore.cardData}
            >
              <AccordionSummary
                className={classes.heading}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <FormHeader
                  header="3. PAYMENT OPTIONS"
                  showIcon={completedSections.payment}
                />
              </AccordionSummary>
              <AccordionDetails>
                <PaymentSection>
                  <Box display="flex" justifyContent="space-between">
                    <FormSubHeader>Payment Information</FormSubHeader>
                    {completedSections.payment && (
                      <EditLink
                        onClick={() => {
                          setCompletedSections({
                            ...completedSections,
                            payment: false,
                          })
                          cartStore.setCardData(null)
                        }}
                      >
                        EDIT
                      </EditLink>
                    )}
                  </Box>
                  <div>
                    {!completedSections.payment && !cartStore.cardData ? (
                      <>
                        <PaymentForm
                          cardNonceResponseReceived={cardNonceResponseReceived}
                        />
                        <Checkbox
                          checked={cartStore.sameAsShipping}
                          color="primary"
                          onChange={() =>
                            handleSameShippingAddressToggle(
                              !cartStore.sameAsShipping
                            )
                          }
                        />
                        Billing address is the same as my shipping address.
                        <FormSubHeader>Billing Address</FormSubHeader>
                        <Box marginBottom="32px">
                          {cartStore.sameAsShipping ? (
                            <ShippingAddressSummary
                              formData={formData.shipping_address.value}
                            />
                          ) : (
                            <NameAddress
                              formData={cartStore.billingInfo}
                              handleBlur={handleBillingAddress}
                              handleZipBlur={() => {}}
                            />
                          )}
                        </Box>
                        <SecureTransaction />
                      </>
                    ) : (
                      <Box>
                        <ContentWrapper>
                          <div>Card info</div>
                          {cartStore.cardData.brand}
                          {cartStore.cardData.last4}
                          {cartStore.cardData.expMonth}
                          {cartStore.cardData.expYear}
                        </ContentWrapper>
                        <FormSubHeader>Billing Address</FormSubHeader>
                        <ShippingAddressSummary
                          formData={formData.billing_address.value}
                        />
                      </Box>
                    )}
                    <div className="sq-error-message">
                      {errorMessages.map(errorMessage => (
                        <li key={`sq-error-${errorMessage}`}>{errorMessage}</li>
                      ))}
                    </div>
                  </div>
                </PaymentSection>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={
                completedSections.address &&
                completedSections.shipping &&
                completedSections.payment &&
                !completedSections.review
              }
            >
              <AccordionSummary
                aria-controls="panel1a-content"
                className={classes.heading}
                id="panel1a-header"
              >
                <FormHeader
                  header="4. REVIEW ORDER"
                  showIcon={completedSections.payment}
                />
              </AccordionSummary>
              <div style={{ paddingLeft: "16px" }}>
                <FormSubHeader>Order Details</FormSubHeader>
              </div>
              <AccordionDetails>
                <div style={{ width: "100%" }}>
                  {cart.map(item => (
                    <ContentWrapper>
                      <Box display="flex" justifyContent="space-between">
                        <div>
                          {item.name} - ({item.color}) X {item.qty}
                        </div>
                        {item.displayTotal}
                      </Box>
                    </ContentWrapper>
                  ))}
                  <ContentWrapper>
                    <Box display="flex" justifyContent="space-between">
                      <div>Taxes - (7.25%) for CA residents</div>
                      {formatter.format(cartStore.taxes / 100)}
                    </Box>
                  </ContentWrapper>
                  <ContentWrapper>
                    <Box display="flex" justifyContent="space-between">
                      <div>Shipping - Estimate </div>
                      {formatter.format(
                        cartStore.shippingOption?.shippingSelection?.value
                          ?.amount || 0
                      )}
                    </Box>
                  </ContentWrapper>
                  {!!cartStore.promoDiscountAmount && (
                    <ContentWrapper>
                      <Box display="flex" justifyContent="space-between">
                        <div>
                          Discount Code ({cartStore.promoDiscountAmount * 100}%
                          OFF)
                        </div>
                        - ({cartStore.discountDisplay})
                      </Box>
                    </ContentWrapper>
                  )}
                  <ContentWrapper>
                    <Box display="flex" justifyContent="space-between">
                      <div>Sub Total </div>
                      {formatter.format(cartStore.subTotal / 100)}
                    </Box>
                  </ContentWrapper>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    marginTop="32px"
                  >
                    <Button
                      className={classes.button}
                      variant="contained"
                      color="primary"
                      onClick={handleOrderSubmission}
                      fullWidth
                      disabled={loading}
                    >
                      {loading ? "Processing Order..." : "Place Order"}
                    </Button>
                  </Box>
                  {!loading && orderError && (
                    <Box mt={2} mb={2}>
                      <span style={{ color: "red" }}> {orderError} </span>
                    </Box>
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          <OrderSummary>
            <OrderSummaryHeader>Order Summary</OrderSummaryHeader>
            <OrderContent>
              {cart.map(item => (
                <CartItem item={item} readOnly />
              ))}
              {cart.map(item => (
                <OrderLineItem>
                  <CartSubheader>
                    {item.name} - ({item.color}) X {item.qty}
                  </CartSubheader>
                  {item.displayTotal}
                </OrderLineItem>
              ))}
              <OrderLineItem>
                <CartSubheader>Taxes - (7.25%) for CA residents</CartSubheader>
                {formatter.format(cartStore.taxes / 100)}
              </OrderLineItem>
              <OrderLineItem>
                <CartSubheader>Shipping - (Estimate)</CartSubheader>
                {formatter.format(
                  cartStore.shippingLineItem.base_price_money.amount / 100
                )}
              </OrderLineItem>
              {cartStore.promoDiscountAmount > 0 && (
                <>
                  <OrderLineItem>
                    <CartSubheader>
                      Discount Code - ({cartStore.promoDiscountAmount * 100}%
                      OFF)
                    </CartSubheader>
                    - ({cartStore.discountDisplay})
                  </OrderLineItem>
                  <RemovePromoText
                    onClick={() => {
                      cartStore.setPromoDiscountAmount(null)
                      setPromoSubmitted(false)
                      setPromoCodeText(null)
                    }}
                  >
                    Remove Promo
                  </RemovePromoText>
                </>
              )}
              <OrderLineItem>
                {!cartStore.promoDiscountAmount && (
                  <>
                    <TextField
                      id="outlined-basic"
                      label="DISCOUNT CODE"
                      variant="outlined"
                      value={promoCodeText}
                      onChange={e => setPromoCodeText(e.target.value)}
                      fullWidth
                    />
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      marginLeft="16px"
                    >
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => {
                          cartStore.addPromoCode(promoCodeText)
                          setPromoSubmitted(true)
                        }}
                      >
                        Apply
                      </Button>
                    </Box>
                  </>
                )}
              </OrderLineItem>
              {!cartStore.promoDiscountAmount && promoSubmitted && (
                <Box mb={2} style={{ color: "red" }}>
                  Invalid Promo Code
                </Box>
              )}
              <Divider orientation="horizontal" variant="fullWidth" light />
              <OrderLineItem>
                <CartSubheader>Total Cost </CartSubheader>{" "}
                {formatter.format(cartStore.subTotal / 100)}
              </OrderLineItem>
            </OrderContent>
          </OrderSummary>
        </CartContent>
      </PageLayout>
    </Layout>
  )
}

export default inject("cart")(observer(CheckOutPage))
