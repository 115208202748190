import React, { useState } from "react"
import { useQuery } from "react-query"
import styled from "styled-components"
import { makeStyles } from "@material-ui/core/styles"
import FormControl from "@material-ui/core/FormControl"
import { Button } from "@material-ui/core"
import { inject, observer } from "mobx-react"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginBottom: "16px",
  },
  heading: {
    borderRadius: 0,
    boxShadow: 0,
    background: "black",
    fontSize: "15px",
    fontWeight: 700,
    fontFamily: "Poppins,sans-serif",
    color: "white",
  },

  input: {
    color: "black",
    width: "100%",
    borderRadius: 0,
  },

  button: {
    backgroundColor: "#4A90E2",
    color: "white",
    height: "56px",
  },

  icon: {
    color: "white",
  },
  shipping: {
    width: "100%",
  },
}))

const ShippingOptions = styled.div`
  margin: 32px 0;
`

const ButtonContainer = styled.div`
  margin-top: ${props => (props.mt ? props.mt : "0")};
  margin-bottom: ${props => (props.mb ? props.mb : "0")};
  margin-right: ${props => (props.mr ? props.mr : "0")};
  margin-left: ${props => (props.ml ? props.ml : "0")};
  display: flex;
  justify-content: flex-end;
`

const ShippingForm = ({ cart: cartStore, onSubmit, getShippingRates }) => {
  const classes = useStyles()
  const formData = cartStore.formData
  const initialShippingState = JSON.stringify(
    formData?.shippingSelection?.value || ""
  )
  const [shippingOption, setShippingOption] = useState(initialShippingState)
  const {
    zip,
    address,
    state,
    city,
    name,
    email,
    phone,
  } = cartStore.shippingInfo

  const { data, isError, isLoading } = useQuery(
    `${state.value}-${zip.value}-${cartStore.cartWeight}`,
    () =>
      getShippingRates({
        address: address?.value,
        state: state?.value,
        city: city?.value,
        postal_code: zip?.value,
        contact_name: name,
        contact_email: email,
        contact_phone: phone,
        weight: cartStore.cartWeight,
      }),
    {
      enabled:
        !!zip.value && !!address.value && !!state.value && !!getShippingRates,
    }
  )

  const handleSubmit = () => {
    if (shippingOption) {
      cartStore.setShippingOption(JSON.parse(shippingOption))
      onSubmit()
    }
  }

  const noShippingCode =
    cartStore.discountCode === "ECLIPSE_2021" ||
    cartStore.discountCode === "HORIZON_2021"

  const defaultRate = {
    // calculate based on number of items in cart
    courier_id: "USPS fallback",
    total_charge: noShippingCode ? 0 : Math.round(cartStore.cartCount * 10),
    amount: noShippingCode ? 0 : Math.round(cartStore.cartCount * 10),
    courier_name: "USPS",
    shipping_type: "USPS",
    fallback: true,
  }
  const rates = []
  // const rates = data?.data.rates || []
  const shouldShowDefault = true
  // (rates.length === 0 && !isLoading) ||
  // (isError && !isLoading) ||
  // (noShippingCode && !isLoading)
  return (
    <div className={classes.shipping}>
      <ShippingOptions>
        {isLoading && <div>Getting shipping rates</div>}
        <FormControl variant="outlined" fullWidth>
          <RadioGroup
            aria-label="shippingOption"
            name="shippingOption"
            value={shippingOption}
            onChange={e => setShippingOption(e.target.value)}
          >
            {shouldShowDefault && (
              <FormControlLabel
                value={JSON.stringify({
                  ...defaultRate,
                })}
                control={<Radio />}
                label={`${defaultRate.courier_name} (Est Cost - $${defaultRate.total_charge})`}
              />
            )}

            {rates.map(item => {
              return (
                <FormControlLabel
                  key={item.courier_id}
                  value={JSON.stringify({
                    carrier: item.courier_name,
                    shipping_type: item.courier_id,
                    amount: item.total_charge,
                    name: item.courier_name,
                  })}
                  control={<Radio />}
                  label={`${item.courier_name} (Est Cost - $${item.total_charge})`}
                />
              )
            })}
          </RadioGroup>
          <ButtonContainer mt="32px">
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              fullWidth
              disabled={!JSON.parse(shippingOption)}
            >
              Next Section
            </Button>
          </ButtonContainer>
        </FormControl>
      </ShippingOptions>
    </div>
  )
}
export default inject("cart")(observer(ShippingForm))
